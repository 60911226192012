import React from 'react';
import styled from 'styled-components';

import { Section, Container, SanityBlockContent } from '.';
import { useSanity } from '../hooks';

const StyledTagline = styled.span`
  color: ${(props) => props.$spanColor};
`;
const RichTextAlignment = function ({
  block,
  raw,
  index,
  alignment,
  idName,
  _rawBody,
  header,
}) {
  const { primary, secondary, accent, neutral, hero } = useSanity();

  return (
    // <Section padding="lg" bgColor={neutral?.white?.color}>
    <div className="px-2 lg:px-0">
      <div className="text-center mt-24 lg:mt-36">
        <StyledTagline
          $spanColor={accent?.dark?.color}
          className="text-base font-semibold uppercase tracking-wider"
        >
          {header?.tagline}
        </StyledTagline>
        <h1 className="mt-2 text-3xl font-extrabold tracking-tight sm:text-4xl lg:max-w-3xl mx-auto">
          {header?.headline}
        </h1>
      </div>
      <Container
        padding="none"
        initialOpacity={1}
        initialScale={1}
        classes="mt-12"
      >
        <article
          // idName={idName}
          className={`mx-auto max-w-3xl space-y-6 text-lg ${alignment}`}
        >
          <SanityBlockContent
            blocks={_rawBody}
            initialOpacity={1}
            initialScale={1}
          />
        </article>
      </Container>
    </div>
    // </Section>
  );
};

export default RichTextAlignment;
