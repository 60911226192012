import React, { useEffect } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { useSanity } from '../hooks';
import {
  Button,
  Col,
  Container,
  Flex,
  FormHero,
  Grid,
  Overlay,
  SanityBlockContent,
  Section,
} from '.';

const StyledHeroHeadline = styled(motion.h1)`
  color: ${(props) => props.$headline};
`;

const StyledHeroSubheadline = styled(motion.div)`
  color: ${(props) => props.$subheadline};
`;

const StyledHeroChecklist = styled(motion.div)`
  color: ${(props) => props.$color};
  background-color: ${(props) => props.$bgColor};
`;

const StyledReviewsGoogle = styled(motion.div)`
  background-color: ${(props) => props.$bgColor};
`;

const StyledReviewsFacebook = styled(motion.div)`
  background-color: ${(props) => props.$bgColor};
`;

const HeroCenter = ({
  block,
  raw,
  index,
  headlineWidth,
  subheadlineWidth,
  headline,
  bgImg,
}) => {
  // const { headline, bgImg, alt } = block;

  const variants = {
    hidden: { opacity: 0, scale: 0.8, x: -100 },
    visible: { opacity: 1, scale: 1, x: 0 },
  };

  const controls = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: true });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
    if (!inView) {
      controls.start('hidden');
    }
  }, [controls, inView]);

  const { info, colors, hero, primary, secondary, accent, neutral } =
    useSanity();

  return (
    <Section
      padding="custom"
      idName="hero"
      classes="relative flex flex-col place-content-center place-items-center pt-[10rem] lg:pt-[12rem] pb-[5rem]"
    >
      <div className="absolute inset-0">
        <GatsbyImage
          image={bgImg?.asset?.gatsbyImageData}
          alt={bgImg?.alt}
          className="w-full h-full object-cover"
        />
        <Overlay bgColor={hero?.overlay?.color} />
      </div>
      <motion.Grid
        ref={ref}
        variants={variants}
        initial="hidden"
        animate={controls}
        className="lg:grid-cols-1 gap-8 mx-auto"
      >
        <Flex classes="p-2 lg:p-10 flex-col justify-center relative text-center lg:text-center">
          <div className="space-y-8 max-w-3xl mx-auto">
            <StyledHeroHeadline
              ref={ref}
              variants={variants}
              initial="hidden"
              animate={controls}
              transition={{ duration: 0.5, delay: 0 }}
              className={`mx-auto ${headlineWidth}`}
              $headline={hero?.headline?.color}
            >
              <span className="!drop-shadow-headline-lg">{headline}</span>
            </StyledHeroHeadline>
            <StyledHeroSubheadline
              ref={ref}
              variants={variants}
              initial="hidden"
              animate={controls}
              transition={{ duration: 0.5, delay: 0.2 }}
              className={`mx-auto space-y-4 ${subheadlineWidth}`}
              $subheadline={hero?.tagline?.color}
            >
              <SanityBlockContent blocks={raw?.subheadline} />
            </StyledHeroSubheadline>
          </div>
          {/* <div>
            <Grid classes="mt-16 lg:grid-cols-2 gap-12">
              <StyledHeroChecklist
                ref={ref}
                variants={variants}
                initial="hidden"
                animate={controls}
                transition={{ duration: 0.5, delay: 0.4 }}
                className={`bg-gray-900/60 bg-clipping-path backdrop-filter backdrop-blur-lg p-6 text-lg lg:text-xl m-auto h-full space-y-12 rounded-xl shadow-xl ${subheadlineWidth} flex flex-col justify-center items-center`}
                $color={hero?.tagline?.color}
                // $bgColor={neutral?.dark?.color}
              >
                <SanityBlockContent blocks={raw?.checklist} />
                <div className="flex flex-row lg:flex-row self-center gap-6 lg:gap-6">
                  <StyledReviewsGoogle
                    ref={ref}
                    variants={variants}
                    initial="hidden"
                    animate={controls}
                    transition={{ duration: 0.5, delay: 0.6 }}
                    $bgColor={primary?.default?.color}
                    className="w-[8rem] lg:w-52 p-3 rounded-xl shadow-xl text-xs lg:text-lg text-center mx-auto space-y-2 flex flex-col justify-center items-center"
                  >
                    <span className="font-bold text-slate-300">
                      Google Reviews
                    </span>
                    <Flex classes="text-xs">
                      <i className="fab fa-google text-white mr-2"></i>
                      <i className="fas fa-star text-yellow-500"></i>
                      <i className="fas fa-star text-yellow-500"></i>
                      <i className="fas fa-star text-yellow-500"></i>
                      <i className="fas fa-star text-yellow-500"></i>
                      <i className="fas fa-star text-yellow-500"></i>
                    </Flex>
                  </StyledReviewsGoogle>
                  <StyledReviewsFacebook
                    ref={ref}
                    variants={variants}
                    initial="hidden"
                    animate={controls}
                    transition={{ duration: 0.5, delay: 0.8 }}
                    $bgColor={primary?.default?.color}
                    className="w-[8rem] lg:w-52 p-3 rounded-xl shadow-xl text-xs lg:text-lg text-center mx-auto space-y-2 flex flex-col justify-center items-center"
                  >
                    <span className="font-bold text-slate-300">
                      Facebook Reviews
                    </span>
                    <Flex classes="text-xs">
                      <i className="fab fa-facebook text-white mr-2"></i>
                      <i className="fas fa-star text-yellow-500"></i>
                      <i className="fas fa-star text-yellow-500"></i>
                      <i className="fas fa-star text-yellow-500"></i>
                      <i className="fas fa-star text-yellow-500"></i>
                      <i className="fas fa-star text-yellow-500"></i>
                    </Flex>
                  </StyledReviewsFacebook>
                </div>
              </StyledHeroChecklist>
              <motion.div
                ref={ref}
                variants={variants}
                initial="hidden"
                animate={controls}
                transition={{ duration: 0.5, delay: 1.0 }}
                className="overflow-hidden px-2"
              >
                <FormHero formName="form-hero" />
              </motion.div>
            </Grid>
          </div> */}
        </Flex>
      </motion.Grid>
    </Section>
  );
};

export default HeroCenter;
