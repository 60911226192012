import React from 'react';

import {
  DynamicFeedProducts,
  DynamicFeedServices,
  DynamicFeedProjects,
  DynamicFeedCaseStudies,
  DynamicFeedReviews,
  DynamicFeedEvents,
} from '.';

const DynamicFeeds = ({ block, raw, index }) => {
  const { type, layout, tag, media, idName, header } = block;

  switch (type) {
    case 'products':
      return (
        <DynamicFeedProducts
          block={block}
          raw={raw}
          index={index}
          idName={idName}
          header={header}
          layout={layout}
          tag={tag}
          media={media}
        />
      );
    case 'services':
      return (
        <DynamicFeedServices
          block={block}
          raw={raw}
          index={index}
          idName={idName}
          header={header}
          layout={layout}
          tag={tag}
          media={media}
        />
      );
    case 'projects':
      return (
        <DynamicFeedProjects
          block={block}
          raw={raw}
          index={index}
          idName={idName}
          header={header}
          layout={layout}
          tag={tag}
          media={media}
        />
      );
    case 'caseStudies':
      return (
        <DynamicFeedCaseStudies
          block={block}
          raw={raw}
          index={index}
          idName={idName}
          header={header}
          layout={layout}
          tag={tag}
          media={media}
        />
      );
    case 'reviews':
      return (
        <DynamicFeedReviews
          block={block}
          raw={raw}
          index={index}
          idName={idName}
          header={header}
          layout={layout}
          tag={tag}
          media={media}
        />
      );
    case 'events':
      return (
        <DynamicFeedEvents
          block={block}
          raw={raw}
          index={index}
          idName={idName}
          header={header}
          layout={layout}
          tag={tag}
          media={media}
        />
      );
    default:
      return (
        <DynamicFeedServices
          block={block}
          raw={raw}
          index={index}
          idName={idName}
          header={header}
          layout={layout}
          tag={tag}
          media={media}
        />
      );
  }
};

export default DynamicFeeds;
