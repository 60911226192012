import React, { useEffect, useRef, useState, useCallback } from 'react';
import styled from 'styled-components';

import SVG from 'react-inlinesvg';

import { SanityBlockContent, Section, Container, Grid, Col } from '.';
import { useSanity } from '../hooks';

const StyledSection = styled((props) => <Section {...props} />)`
  background-color: ${(props) => props.$bgColor};
  & span {
    color: ${(props) => props.$spanColor};
  }
`;

const StyledTagline = styled.span`
  color: ${(props) => props.$spanColor};
`;

const PrivacyPolicyWarranty = ({ block, raw, index, idName, header }) => {
  const { info, primary, secondary, accent, neutral, hero } = useSanity();

  return (
    <StyledSection
      idName={idName}
      padding="custom"
      bgColor={neutral?.lighter?.color}
      h2Color={neutral?.darker?.color}
      h3Color={primary?.dark?.color}
      $spanColor={accent?.light?.color}
      classes="pt-[13rem] lg:pt-[15rem] pb-24"
    >
      <Container
        initialOpacity={1}
        initialScale={1}
        classes="max-w-md px-4 text-center sm:max-w-3xl lg:max-w-7xl"
      >
        <StyledTagline
          $spanColor={accent?.dark?.color}
          className="text-base font-semibold uppercase tracking-wider"
        >
          {info?.name}
        </StyledTagline>
        <h1 className="mx-auto mt-2 text-3xl font-extrabold tracking-tight sm:text-4xl lg:max-w-3xl">
          Our Puppy Warranty
        </h1>
        <Container initialOpacity={1} initialScale={1} type="sm">
          <div className="space-y-8 text-left lg:max-w-3xl mx-auto mt-12">
            <p>
              Upon purchasing the puppy, the Buyer agrees not to spay or neuter
              said puppy before 18 months of age; violation of said terms will
              void this warranty.
            </p>

            <p>
              Registering your puppy and the microchip with The American Kennel
              Club is an essential part of responsible dog ownership. Sisu
              Retrievers will pay the cost of the AKC Registration and AKC
              Reunite (microchip registration).
            </p>

            <p>
              All Sisu breeding stock and the stud dogs we use are screened for
              the following: OFA/FCI Hips, OFA/FCI Elbows, and OFA Eyes
              (yearly). Sisu Retrievers expect the Purchaser to remember that
              environmental factors may contribute to health issues. Copies of
              health clearances from sire and dam are included in the puppy
              information package.
            </p>
            <p>
              To validate the health warranty, the Purchaser must have the puppy
              examined by a veterinarian within 5 days of the transfer. If the
              puppy is diagnosed with an existing medical problem that was not
              previously disclosed, the puppy may be returned for a full refund.
              If the Purchaser wishes to retain possession of the puppy, the
              Breeder agrees to pay the pet insurance deductible. The Purchaser
              must provide the Breeder with a letter from the attending
              veterinarian specifying the nature of the problem and copies of
              the medical record and expenses. If requested by the Breeder, the
              Purchaser must provide the Breeder with an evaluation from a
              second veterinarian of the Breeder's choice at the Breeder's
              expense before any reimbursement is made.
            </p>

            <p>
              The Purchaser needs to understand that until a Labrador has
              completed its growing, only moderate exercise is recommended. We
              will not warranty dogs that have worn harnesses. Harnesses can do
              major damage to the structure of a dog if worn while dogs are
              still growing.
            </p>
            <p>
              We have taken reasonable precautions to prevent acquired
              hereditary disorders. If, within the first 12 months of age, a
              purchased puppy develops a serious inherited disorder that will
              prevent the dog from living a fulfilling pet life, the Breeder
              will provide the Purchaser with a partial refund of the purchase
              price. Examples of serious inherited disorders include
              documentable severe to non-functional hip dysplasia, OFA grade II
              to III elbow dysplasia, inherited epilepsy, clinically significant
              juvenile cataracts, and moderate to severe TVD. The Purchaser must
              provide the Breeder with copies of the medical records and expense
              documentation and a letter from the attending veterinarian within
              30 days of the discovery. The Breeder reserves the right to ask
              for a second opinion at Breeder’s expense. If requested, the
              Purchaser may retain possession of the dog or return the dog to
              the Breeder.
            </p>
            <p>
              I understand that the Labrador Retriever I am purchasing is being
              sold on an AKC Limited Registration.
            </p>
            <ul className="sanity-ul">
              <li>
                Must be kept intact until the age of 18 months. Spaying or
                neutering before 18 months of age will void the warranty.
              </li>
              <li>
                Must be examined by my veterinarian within 5 days of transfer,
                but vaccines will not be due for another 3-4 weeks. Any vaccines
                given during this appointment will void the warranty.
              </li>
              <li>
                Must be kept on Pet Health Insurance for the first year of life.
                The cost of such insurance is the buyer's responsibility.
                Failure to comply will void the warranty.
              </li>
              <li>Use of a harness will void your dog's health warranty.</li>
            </ul>

            <p>
              ** Use of Nu Vet Plus or Nu Joint is encouraged during the puppy’s
              growth period.
            </p>
            <p>
              I understand that failure to comply with the above statements will
              void my dog’s warranty.
            </p>
            <p>
              If for any reason, during this dog's life, the Purchaser cannot
              care for the dog we require that they contact us.
            </p>
            <p>
              By my signature, as the Purchaser, I acknowledge reading the Puppy
              Warranty and understand the requirements from me to maintain this
              Puppy Warranty.
            </p>
          </div>
        </Container>
      </Container>
    </StyledSection>
  );
};

export default PrivacyPolicyWarranty;
