import React, { useEffect, useRef, useState, useCallback } from 'react';
import styled from 'styled-components';

import SVG from 'react-inlinesvg';

import { SanityBlockContent, Section, Container, Grid, Col } from '.';
import { useSanity } from '../hooks';

const StyledSection = styled((props) => <Section {...props} />)`
  background-color: ${(props) => props.$bgColor};
  & span {
    color: ${(props) => props.$spanColor};
  }
`;

const StyledTagline = styled.span`
  color: ${(props) => props.$spanColor};
`;

const PrivacyPolicyPuppy = ({ block, raw, index, idName, header }) => {
  const { info, primary, secondary, accent, neutral, hero } = useSanity();

  return (
    <StyledSection
      idName={idName}
      padding="custom"
      bgColor={neutral?.lighter?.color}
      h2Color={neutral?.darker?.color}
      h3Color={primary?.dark?.color}
      $spanColor={accent?.light?.color}
      classes="pt-[13rem] lg:pt-[15rem] pb-24"
    >
      <Container
        initialOpacity={1}
        initialScale={1}
        classes="max-w-md px-4 text-center sm:max-w-3xl lg:max-w-7xl"
      >
        <StyledTagline
          $spanColor={accent?.dark?.color}
          className="text-base font-semibold uppercase tracking-wider"
        >
          {info?.name}
        </StyledTagline>
        <h1 className="mx-auto mt-2 text-3xl font-extrabold tracking-tight sm:text-4xl lg:max-w-3xl">
          Our Puppy Policy
        </h1>
        <Container initialOpacity={1} initialScale={1} type="sm">
          <div className="space-y-8 text-left lg:max-w-3xl mx-auto mt-12">
            <p>
              In accordance with Massachusetts State law, puppies are not
              released until 8 weeks of age. All pups are born with close
              supervision. If not manually attended, they are video attended if
              I have to step away. They do not go outside until they have their
              eyes open and can walk.
            </p>
            <p>
              All pups will have had their first puppy shot and will have been
              dewormed using a standard worming regiment. The puppies will be
              examined by a licenced veterinarian. Your puppy will also be
              microchipped unless you request otherwise.
            </p>
            <p>
              Puppies go home with a puppy pack with some helpful hints and
              suggestions, food and supplement information, and some feeding
              information for the first few months. Puppies are AKC registered
              (or registrable) on AKC limited registrations.
            </p>
            <p>
              We firmly believe that all puppies must go to puppy kindergarten
              and/or basic obedience and we require intent to train the pup.
            </p>
            <p>
              I realize not everyone can come to visit. Email is great and at
              some point a phone conversation will be necessary. I schedule
              visits prior to the birth of puppies or after puppies are 5 to 6
              weeks old.
            </p>
            <p>
              To keep everyone healthy, I must ask that you please not visit me
              after you have visited other kennels.
            </p>
            <p>
              Thank you for looking through the site and considering one of our
              dogs for your next family member.
            </p>
          </div>
        </Container>
      </Container>
    </StyledSection>
  );
};

export default PrivacyPolicyPuppy;
